import { Component, Inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { PushNotificationService } from "./shared/services/push-notification.service";
import { PushNotificationService as PushNotification } from "ng-push-notification";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "monito-design";
  public identifier = environment.identifier;
  constructor(
    private router: Router,
    private notificationFirebase: PushNotificationService,
    @Inject(PushNotification) private pushNotification: PushNotification
  ) {
    if (notificationFirebase.isSupported()) {
      notificationFirebase.requestPermision().then((token: string) => {
        localStorage.setItem(`fcm_${this.identifier}`, token.trim());
      });
    }

    this.initializeApp();
  }

  public showNotification(body: {
    title: string;
    message: string;
    icon?: string;
  }) {
    this.pushNotification.show(
      body.title,
      {
        body: body.message,
        icon: body.icon ?? null,
      },
      10000 // close delay.
    );
    // Or simply this:
    // this.pushNotification.show('And that too!');
  }

  ngOnInit(): void {
    if (this.notificationFirebase.isSupported()) {
      this.notificationFirebase.receiveMessage().subscribe((payload: any) => {
        // aqui push.org
        console.log(payload);
        if (payload.notification) {
          this.showNotification({
            title: payload.notification.title,
            message: payload.notification.body,
          });
          if (
            payload.data &&
            payload.data.action === "refresh" &&
            payload.data.webPage
          ) {
            this.router.navigate([payload.data.webPage]);
          }
        }
        console.log(JSON.stringify(payload));
      });
    }
  }

  initializeApp() {}
}
