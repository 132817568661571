import { HttpRequestStatusCode } from "./../utils/http-request-status-code";
import { Router } from "@angular/router";
import { StorageService } from "src/app/shared/services/storage.service";
import { SocialAuthService } from "angularx-social-login";
import { MessageService } from "./../services/message.service";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import {
  catchError,
  retry,
  delay,
  retryWhen,
  take,
  concat,
  tap,
  map,
} from "rxjs/operators";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    public message: MessageService,
    private authService: SocialAuthService,
    public storage: StorageService,
    public router: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (!req.url.includes("123")) {
    //   return next.handle(req);
    // }
    return next.handle(req).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => this.errorHandler(error))
    );
    // return next.handle(req).pipe(
    //   retryWhen(error => error.pipe(
    //             delay(5000),
    //             take(3),
    //             concat(throwError(error)))),
    //   catchError((error : HttpErrorResponse) => this.errorHandler(error))
    // );
  }

  errorHandler(_error: HttpErrorResponse) {
    const { status } = _error;
    const { error } = _error;
    console.log(error);
    if (
      status === HttpRequestStatusCode.UNAUTHORIZED ||
      status === HttpRequestStatusCode.FORBIDDEN
    ) {
      this.storage.getToken() != "" ? this.logout() : null;
      return throwError("Error personalizado");
    }
    error.data ? this.message.error(error.data._message ? error.data._message : error.data): this.message.error('Ocorreu um erro. Por favor, tente novamente em alguns minutos');
    return throwError("Error personalizado");
  }

  public logout() {
    this.authService
      .signOut()
      .then(() => {
        this.storage.logOut();
        setTimeout(() => {
          window.location.reload();
        }, 1800);
        this.router.navigate([""]);
      })
      .catch((error) => {
        this.storage.logOut();
        setTimeout(() => {
          window.location.reload();
        }, 1800);
        this.router.navigate([""]);
      });
  }
}
