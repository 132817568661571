import { Injectable } from "@angular/core";
import firebase from "firebase";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  public messagingFirebase: firebase.messaging.Messaging;
  constructor() {
    firebase.initializeApp(environment.firebaseConfig);
    if (firebase.messaging.isSupported()) {
      this.messagingFirebase = firebase.messaging();
    }
  }

  isSupported() {
    return firebase.messaging.isSupported();
  }

  requestPermision = () => {
    return new Promise(async (resolve, reject) => {
      const permision = await Notification.requestPermission();
      if (permision === "granted") {
        const tokenFirebase = await this.messagingFirebase.getToken();
        resolve(tokenFirebase);
      } else {
        //reject(new Error("no se obtuvo el token"));
        resolve(null);
      }
    });
  };

  private messageObservable = new Observable((observe) => {
    this.messagingFirebase.onMessage((payLoad) => {
      observe.next(payLoad);
    });
  });

  receiveMessage() {
    return this.messageObservable;
  }
}
