// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  code: "SCWE&A",
  identifier: "65fda857ab5df",//645f23704732f
  name: "ClientShop",
  defaultLanguage: "pt",
  host: "https://manager.amappzing.com.br/",
  // host: "http://arya.com.br/",
  url: "https://clientshop.amappzing.com.br",
  firebaseConfig: {
    apiKey: "AIzaSyB9e4ANMd-MCZGzy8OpuiGI8cjkCreQMMc",
    authDomain: "arya-dc57e.firebaseapp.com",
    projectId: "arya-dc57e",
    storageBucket: "arya-dc57e.appspot.com",
    messagingSenderId: "909206300603",
    appId: "1:909206300603:web:7c9df6350711a5760f869e",
    measurementId: "G-9G06HBFPLH"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
